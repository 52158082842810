<script>
import api from '@/services/api';

export default {
    name: 'PhoneListView',

    props: {
        product: {
            type: Object,
            required: true
        },
        imgUploaded: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: () => 'product'
        }
    },

    data() {
        return {
            phoneList: []
        };
    },

    async mounted() {
        await this.getProducts();
    },

    computed: {
        phoneProducts: {
            get() {
                return this.phoneList;
            },
            set(value) {
                return value.unshift(this.product);
            }
        }
    },
    methods: {
        async getProducts() {
            if (this.type === 'product') {
                const body = {
                    method: 'coin.product_find_all',
                    params: {
                        page: 1,
                        limit: 30
                    }
                };
                await api.coin
                    .fetchCoinProducts(body)
                    .then((response) => {
                        this.phoneList = response.data.result;
                        this.phoneList.unshift(this.product);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } else {
                const body = {
                    page: 1,
                    limit: 30
                };
                await api.coin
                    .fetchCoinBonuses(body)
                    .then((response) => {
                        this.phoneList = response.data.result;
                        this.phoneList.unshift(this.product);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        }
    }
};
</script>

<template>
    <div class="gifts-block">
        <div class="gift-title">{{ $t('navigation.prizes') }}</div>
        <div class="gift-list">
            <div v-for="(product, index) in phoneProducts" :key="index + type" class="gift-card">
                <div class="gift-card__image">
                    <img
                        v-if="product.images && product.images.length"
                        :src="product.images[0].url || product.images[0].path"
                        alt=""
                    />
                    <img v-else :src="require('@/assets/images/icons/no-photo.png')" alt="" />
                </div>
                <div class="gift-card__content">
                    <h5>{{ product.name.uz }}</h5>
                    <div class="price">
                        <img alt="" src="@/assets/images/icons/coin.png" />
                        <p>{{ product.price }}</p>
                    </div>
                </div>
                <div class="gift-card__button">
                    <p>{{ $t('phone.add_to_basket') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.gifts-block {
    padding: 1rem 0.75rem;
    height: 95%;
    overflow-y: auto;
    max-width: 100%;
}

.gift-title {
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 25px;
    color: #333333;
}

.gift-list {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 8px));
    max-width: 100%;
    max-height: 100%;
    gap: 1rem;
    flex-wrap: wrap;
}

.gift-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    padding: 16px 10px;
    background: #eeeeee;
    border-radius: 8px;
    max-width: 300px;

    &__image {
        width: 50px;
        height: 50px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 0.5rem;

        h5 {
            font-weight: 600;
            font-size: 13px;
            line-height: 22px;
            text-align: center;
            max-width: 140px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            color: #333333;
            margin-bottom: 0;
        }

        .price {
            display: flex;
            column-gap: 0.5rem;

            p {
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                color: #f2d207;
                margin-bottom: 0;
                overflow: hidden;
                max-width: 60px;
                text-overflow: ellipsis;
            }

            img {
                width: 100%;
                max-width: 20px;
                max-height: 20px;
                object-fit: contain;
            }
        }
    }

    &__button {
        background: linear-gradient(122.82deg, #f2d207 0%, #ffa329 100%);
        border-radius: 16px;
        padding: 6px 12px;

        p {
            font-weight: 500;
            font-size: 11px;
            line-height: 18px;
            color: #fff;
            margin-bottom: 0;
        }

        &.activate {
            padding: 7px 10px;
        }
    }

    &__counter {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;

        p {
            font-size: 20px;
            line-height: 28px;
            font-weight: 600;
            letter-spacing: -0.4px;
            color: #333333;
            max-width: 60px;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        & .gift-card__button {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 2rem;
            max-height: 2rem;
            padding: 0.25rem !important;
        }
    }
}
</style>
