<script>
import { checkLang } from '@/util/helper';
import { directive as swiperDirective } from 'vue-awesome-swiper';

export default {
    name: 'PhoneProductView',
    methods: { checkLang },
    directives: {
        swiper: swiperDirective
    },
    data() {
        return {
            swiperDefaultOption: {
                slidesPerView: 1,
                spaceBetween: 20,
                direction: 'horizontal',
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
                paginationClickable: true,
                draggable: true,
                loop: false
            }
        };
    },
    props: {
        product: {
            type: Object,
            required: false
        },
        oldQty: {
            type: [Number, String],
            required: false
        },
        imgUploaded: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        lastQty() {
            return this.oldQty
                ? parseInt(this.oldQty) + parseInt(this.product.qty)
                : this.product.qty;
        }
    }
};
</script>

<template>
    <div class="market-product">
        <div
            ref="swiper"
            v-swiper="swiperDefaultOption"
            class="swiper swiper-container market-product__image"
        >
            <div class="swiper-wrapper">
                <template v-if="imgUploaded">
                    <div v-for="image in product.images" :key="image.id" class="swiper-slide">
                        <img v-if="image && image.url" :src="image.url" alt="" />
                        <img
                            v-else
                            :src="require('@/assets/images/phone-frame/no-photo.png')"
                            alt=""
                        />
                    </div>
                </template>
            </div>
            <div class="swiper-pagination swiper-pagination-bullets"></div>
        </div>

        <div class="market-product__content">
            <div class="market-product__top">
                <p class="market-product__title">{{ checkLang(product.name) }}</p>
                <div class="market-product__price">
                    <img :src="require('@/assets/images/phone-frame/coin.png')" alt="" />
                    <p>{{ product.price }}</p>
                </div>
            </div>
            <p class="market-product__amount">
                Количество:
                <span>
                    {{ lastQty }}
                    <template v-if="product.measurement">
                        {{ checkLang(product.measurement.name) }}
                    </template>
                </span>
            </p>
            <p class="market-product__description-title">Описание</p>
            <p class="market-product__description" v-html="checkLang(product.description)"></p>
            <button class="market-product__btn">
                <img :src="require('@/assets/images/phone-frame/add.svg')" alt="" />
                <p>В корзину</p>
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import 'swiper/swiper';

.market-product {
    position: relative;
    height: 95%;
    overflow-y: auto;
    max-width: 100%;

    &__content {
        display: block;
        max-width: 100%;
        padding: 0.5rem;
    }

    &__image {
        width: auto;
        max-width: 98%;
        height: 225px;
        background-color: #f2fbfd;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.5rem 0.5rem 0 0;

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .swiper-slide {
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__top {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        column-gap: 0.5rem;
    }

    &__title {
        font-size: 16px;
        line-height: 25px;
        font-weight: 600;
        letter-spacing: -0.4px;
        color: #333333;
        height: 44px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    &__price {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        padding: 4px 0;

        p {
            font-size: 15px;
            line-height: 20px;
            font-weight: 600;
            color: #f2d207;
            overflow: hidden;
            max-width: 60px;
            text-overflow: ellipsis;
            margin-bottom: 0;
        }

        img {
            width: 20px;
            max-width: 20px;
            object-fit: contain;
        }
    }

    &__amount {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        flex-wrap: wrap;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        letter-spacing: -0.5px;
        color: #797d81;
        margin-bottom: 24px;

        & span {
            overflow: hidden;
            max-width: 200px;
            text-overflow: ellipsis;
            color: #333333;
        }
    }

    &__description-title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        letter-spacing: -0.5px;
        color: #333333;
        margin-bottom: 8px;
    }

    &__description {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        letter-spacing: -0.5px;
        color: #797d81;
        opacity: 0.7;
        margin-bottom: 1.5rem;
        line-break: anywhere;
    }

    &__btn {
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(122.82deg, #f2d207 0%, #ffa329 100%);
        width: 128px;
        height: 34px;
        border-radius: 100px;
        column-gap: 0.5rem;

        img {
            width: 20px;
            height: 20px;
            object-fit: contain;
        }

        p {
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            color: #ffffff;
            margin-bottom: 0;
        }
    }
}
</style>
